import { colors, Divider, Link, ListItemButton, ListItemIcon } from "@mui/material";
import { ActionIconsContainerDesktop, ActionIconsContainerMobile, MyList } from "../../styles/appbar";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import { Colors } from "../../styles/theme";

interface ActionsProp 
{
    matches: boolean
}

export default function Actions(props: ActionsProp) {
    const Component = props.matches ? ActionIconsContainerMobile : ActionIconsContainerDesktop;

    return (
        <Component>
<MyList type="row">
            <Divider orientation="vertical" flexItem />
            <ListItemButton
            href="tel:7597930137"

                sx={{
                    justifyContent: 'center'
                }}
            >
                <ListItemIcon
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        ...props.matches ? { color: Colors.dim_grey} : {}
                    
                    }}
                >
                    <PhoneIcon/>7597930137
                </ListItemIcon>
            </ListItemButton>
            
            <Divider orientation="vertical" flexItem />

            <ListItemButton
                href="https://wa.me/917597930137?text=Request%20for%20appointment%0AName%3A%0ADate%3A%20"
                sx={{
                    justifyContent: 'center'
                }}
            >
                <ListItemIcon
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    ...props.matches ? { color: Colors.dim_grey} : {}
                }}
            >
                <WhatsAppIcon/>WhatsApp
                </ListItemIcon>
            </ListItemButton>
            <Divider orientation="vertical" flexItem />
        </MyList>
        </Component>
        
    );
}