import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { Colors } from "../theme";

export const Treatment = styled(Box)(
    ({theme}) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up("md")]: {
            position: 'relative'
        }
    })
);

export const TreatmentImage = styled('img')(
    ({src, theme}) => ({
        src: `url(${src})`,
        width: '100%',
        backgruond: Colors.light_gray,
        padding: '10px',
        [theme.breakpoints.down("md")]: {
            width: '80%',
            padding: '24px',
        }
    })
);

export const TreatmentMetaWrapper = styled(Box)(
    ({theme}) => ({
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    })
);