import { Container, useTheme } from "@mui/system"
import { Grid, useMediaQuery } from "@mui/material";
import { treatments } from "../../data";
import SingleTreatment from "./SingleTreatment";

export default function Treatments() {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    const renderTreatments = treatments.map(treatment => (
        <Grid item key={treatment.id} xs={2} sm={4} md={4} display='flex' flexDirection={"column"} alignItems="center">
            {matches ? (
                    <SingleTreatment treatment={treatment} matches={matches} />
                ) : (
                    <SingleTreatment treatment={treatment} matches={matches} />
                    // <SingleTreatmentDesktop treatment={treatment} matches={matches} />
                )}
        </Grid>
    ))

    return (
        <Container>
            <Grid container justifyContent={'center'} sx={{
                margin: '20px 4px 10px 4px'
            }}>
                {renderTreatments}
            </Grid>
        </Container> 
    )
}