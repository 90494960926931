import { Box, Grid, List, Typography } from "@mui/material";
import { FooterTitle } from "../../styles/footer/indes";
import { Colors } from "../../styles/theme";
import {Facebook, Instagram, Twitter} from "@mui/icons-material";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export default function Footer() {
    return (
        <Box
            sx={{
                background: Colors.primary,
                color: Colors.white,
                p: {xs: 4, md: 10},
                pt: 12,
                pb: 12,
                fontSize: {xs: '12px', md: '14px'}
            }}
        >
            <Grid container spacing={2} justifyContent='center'>
                <Grid item md={6} lg={4}>
                    <FooterTitle variant="body1">About Us</FooterTitle>
                    <Typography variant="caption">
                    Dr. Ankit Laddha is a highly skilled and knowledgeable General Medicine physician with a passion for delivering exceptional healthcare services to his patients. He completed his MBBS from SMS Medical College Jaipur and went on to earn his MD from Jhalawar Medical College. With years of experience and a deep understanding of general medicine, Dr. Laddha is known for his compassionate and patient-centered approach.                    </Typography>
                    <Box sx={{
                        mt:4,
                        color: Colors.dove_gray
                    }}>
                        <Facebook sx={{mr:1}}/>
                        <Twitter sx={{mr:1}}/>
                        <Instagram/>
                    </Box>
                </Grid>
                <Grid item md={6} lg={4}>
                    <FooterTitle variant="body1">Get In Touch With Us!</FooterTitle>
                    <List sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'left'
                    }}>
                        <Box><PhoneIcon/><Typography variant="caption">7597930137</Typography></Box>
                        <Box><EmailIcon/><Typography variant="caption">nkit5886@gmail.com</Typography></Box>
                    </List>
                </Grid>
            </Grid>
        </Box>
    )
}