import { useEffect } from 'react';
import { Button, Container } from '@mui/material';
import { ThemeProvider } from "@mui/system";
import ResponsiveAppBar from './components/ResponsiveAppBar';
import theme from './styles/theme';
import AppBar from './components/appbar';
import Footer from './components/footer';
import AppDrawer from './components/drawer';
import { UIProvider } from './context/ui';
import Banner from './components/banner';
import Treatments from './components/treatments';


function App() {

  useEffect(() => {
    document.title = "Dr. Ankit Laddha - Home"

  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: '#fff'
        }}
      >
        <UIProvider>

          <AppBar></AppBar> 
          <Banner></Banner>
          <Treatments></Treatments>
        {
          /**
           * App Bar
           * Banner/Slider
           * Promotion or Marketing Message
           * Locations
           * Treatments
           * Footer
           * AppDrawer
           */
        }
        <Footer></Footer>
        <AppDrawer/>

        </UIProvider>
      </Container>
    </ThemeProvider>
    // <ResponsiveAppBar></ResponsiveAppBar>
  );
}

export default App;
