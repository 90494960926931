import { IconButton, List, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Colors } from "../theme";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';



export const AppbarContainer = styled(Box)(() => ({
    display: 'flex',
    marginTop: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 8px'
}));

export const AppbarLogo = styled(LocalHospitalIcon)(
    () => ({
        padding: '4px',
        // flexGrow: 1,
        fontSize: '3em',
        color: Colors.primary
    })
)

export const AppbarHeader = styled(Typography)(
    () => ({
        padding: '4px',
        flexGrow: 1,
        fontFamily: '"Rubik" , "cursive"',
        color: Colors.primary
    })
)

interface MyListProps {
    type?: String
}

export const MyList = styled(List)(
    (props: MyListProps) => ({
        display: props.type == 'row' ? 'flex' : 'block',
        flexGrow: 4,
        justifyContent: 'center',
        alignItems: 'center'
    })
)

export const ActionIconsContainerMobile = styled(Box)(
    () => ({
        display: 'flex',
        background: Colors.shaft,
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        alignItems: 'center',
        zIndex: 99,
        borderTop: `1px solid ${Colors.border}`
    })
)
export const ActionIconsContainerDesktop = styled(Box)(
    () => ({
        flexGrow: 0
    })
)

export const DrawerCloseButton = styled(IconButton)(
    () => ({
        position:'absolute',
        top: 10,
        left: '250px',
        zIndex: 1999
    })
) 