import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { BannerContainer, BannerContent, BannerDescription, BannerImage, BannerTitle } from "../../styles/banner";

export default function Banner() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    return(
    <BannerContainer>
        <BannerImage src="https://www.ankurahospitals.com/wp-content/uploads/2021/03/our-doctor-placeholder.jpg"></BannerImage>
        <BannerContent>
            <Typography variant="h6">
                About Us
            </Typography>
            <BannerTitle variant="h2">
                Dr. Ankit Laddha
            </BannerTitle>
            <BannerDescription variant="subtitle1">
            Dr. Ankit Laddha is a highly skilled and knowledgeable General Medicine physician with a passion for delivering exceptional healthcare services to his patients. He completed his MBBS from SMS Medical College Jaipur and went on to earn his MD from Jhalawar Medical College. With years of experience and a deep understanding of general medicine, Dr. Laddha is known for his compassionate and patient-centered approach.                    
            </BannerDescription>
        </BannerContent>
    </BannerContainer>
    )
}