import { createContext, useContext, useState, ReactNode } from "react";

export const useUIContext = () => useContext(UIContext);

interface Props {
    children?: ReactNode
    // any props that come into the component
}

interface ContextProps {
    drawerOpen: boolean,
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}


const defaultContextValue: ContextProps = {
    drawerOpen: false,
    setDrawerOpen: () => {}
}

export const UIContext = createContext<ContextProps>(defaultContextValue);

export const UIProvider = ({children, ...props}: Props) => {

    const [drawerOpen, setDrawerOpen] = useState(false);

const value: ContextProps = {
        drawerOpen, 
        setDrawerOpen}
    
    return <UIContext.Provider value={value} > {children} </UIContext.Provider>;
}