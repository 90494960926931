import { ListItemText } from "@mui/material";
import { AppbarContainer, AppbarHeader, AppbarLogo, MyList } from "../../styles/appbar"
import Actions from "./actions"


interface AppbarDesktopProps {
    matches: boolean
}

export default function AppbarDesktop(props: AppbarDesktopProps) {


    return (
        <AppbarContainer>
            <AppbarLogo/>
            <AppbarHeader
                sx={{
                    fontSize:'3em'
                }}
            >
                Dr. Ankit Laddha
            </AppbarHeader> 
            <MyList type="row">
                <ListItemText primary="Home"></ListItemText>
                <ListItemText primary="Treatments"></ListItemText>
                <ListItemText primary="Services"></ListItemText>
            </MyList>
            <Actions matches={props.matches}></Actions>
        </AppbarContainer>
    //     <>
    //   <h1>Desktop</h1>
    //     </>
    );
}