import { IconButton } from "@mui/material";
import { AppbarContainer, AppbarHeader,AppbarLogo } from "../../styles/appbar";
import MenuIcon from "@mui/icons-material/Menu"
import Actions from "./actions"
import { useUIContext } from "../../context/ui";


interface AppbarMobileProps {
    matches: boolean
}

export default function AppbarMobile(props: AppbarMobileProps) {

    const {setDrawerOpen} = useUIContext();

    return (
        <AppbarContainer>
            <IconButton onClick={() => setDrawerOpen(true)}>
                <MenuIcon />
            </IconButton>
            
            <AppbarHeader
                sx={{
                    fontSize:'2em'
                }}
                align='center'
            >
                Dr Ankit Laddha
            </AppbarHeader>
            
            <Actions matches={props.matches}></Actions>
        </AppbarContainer>
    );
}