import { Typography } from "@mui/material";
import { TreatmentMetaWrapper } from "../../styles/treatments";

export interface TreatmentProps {
    name: string,
    id: number,
    image?: string
}

interface TreatmentMetaProps {
    treatment: TreatmentProps,
    matches: boolean
}

export default function TreatmentMeta(props: TreatmentMetaProps) {
    return (
        <TreatmentMetaWrapper>
            <Typography variant={props.matches? 'h6': 'h5'} lineHeight={2}>
                {props.treatment.name}
            </Typography>
        </TreatmentMetaWrapper>
    )
}