import { Treatment, TreatmentImage } from "../../styles/treatments";
import TreatmentMeta from "./TreatementMeta";
import { TreatmentProps } from "./TreatementMeta";

interface SingleTreatmentProps {
    treatment: TreatmentProps,
    matches: boolean
}

export default function SingleTreatment(props: SingleTreatmentProps) {
     
    return (
        <Treatment>
            <TreatmentImage src={props.treatment.image}/>
            <TreatmentMeta treatment={props.treatment} matches={props.matches}/>
        </Treatment>
        /**
         * Product Container
         * Image
         * Metadata
         * Product Action Add to card, share,,...
         * Footer
         */
    )
}